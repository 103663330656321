<template>
  <div v-if="page === 'lining'">
    <div class="liningPreview" :style="liningStyles" style="min-height:300px;overflow: hidden">
      <div
        style="
          min-height: 300px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <p>Your lining will looks like this</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LiningPreview",
  props: ["page"],
  computed: {
    ...mapState(["landing"]),
    liningStyles() {
      let styles = "";
      if (this.landing.branding.bg_lining_img) {
        styles += `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        styles += `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
      if (this.landing.branding.lining_margin) {
        styles += `margin: 65px 10px 0 10px`;
      } else {
        styles += `margin: 65px 0 0 0;`;
      }
      return styles;
    },
  },
};
</script>

<style></style>
